/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Repository = {
    properties: {
        type: {
            type: 'string',
        },
        port: {
            type: 'string',
        },
        url: {
            type: 'string',
        },
    },
};