/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ServiceDependency = {
    properties: {
        serviceId: {
            type: 'string',
            isRequired: true,
        },
        required: {
            type: 'boolean',
            isRequired: true,
        },
    },
};