/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Auth = {
    properties: {
        username: {
            type: 'string',
        },
        password: {
            type: 'string',
        },
    },
};