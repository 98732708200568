/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Port = {
    properties: {
        port: {
            type: 'number',
        },
        protocol: {
            type: 'string',
        },
        contextPath: {
            type: 'string',
        },
    },
};