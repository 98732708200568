/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $ServiceDependency = {
    properties: {
        key: {
            type: 'string',
        },
        required: {
            type: 'boolean',
        },
    },
};